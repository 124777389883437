:root {
  /* Base font sizes for different sections */
  --font-size-ltitle-xl: 18px;
  --font-size-ldesc-xl: 18px;
  --font-size-lbutton-xl: 16px;
  --font-size-pheader-xl: 48px;
  /* Card dimensions */
  --img-pwidth-xl: 10rem;
  --img-lwidth-xl: 21rem;
  --img-pheight-xl: 5rem;
  /* Scaling factors */
  --scale-xxs: 0.8;
  --scale-xs: 1;
  --scale-sm: 1;
  --scale-md: 1;
  --scale-lg: 0.84;
  --scale-2xl: 1.2;
  --scale-3xl: 1.5;
  --scale-4xl: 1.8;
}

/* Media queries for scaling */
/* @media (max-width: 424px) { */
:root {
  --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-xxs));
  --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-xxs));
  --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-xxs));
  --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-xxs));
  --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-xxs));
  --img-pheight: calc(var(--img-pheight-xl) * var(--scale-xxs));
  --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-xxs));
}
/* } */
@media (min-width: 425px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-xs));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-xs));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-xs));
    --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-xs));
    --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-xs));
    --img-pheight: calc(var(--img-pheight-xl) * var(--scale-xs));
    --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-xs));
  }
}

@media (min-width: 640px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-sm));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-sm));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-sm));
    --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-sm));

    --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-sm));
    --img-pheight: calc(var(--img-pheight-xl) * var(--scale-sm));
    --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-sm));
  }
  .shadows .leftShadow,
  .shadows .rightShadow {
    height: 7rem !important;
  }
}

@media (min-width: 768px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-md));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-md));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-md));
    --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-md));

    --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-md));
    --img-pheight: calc(var(--img-pheight-xl) * var(--scale-md));
    --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-md));
  }
  .shadows .rightShadow {
    height: 7rem !important;
  }
}

@media (min-width: 1024px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-lg));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-lg));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-lg));
    --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-lg));

    --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-lg));
    --img-pheight: calc(var(--img-pheight-xl) * var(--scale-lg));
    --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-lg));
  }
}

@media (min-width: 1280px) {
  :root {
    --font-size-ltitle: var(--font-size-ltitle-xl);
    --font-size-ldesc: var(--font-size-ldesc-xl);
    --font-size-lbutton: var(--font-size-lbutton-xl);
    --font-size-pheader: var(--font-size-pheader-xl);

    --img-pwidth: var(--img-pwidth-xl);
    --img-pheight: var(--img-pheight-xl);
    --card-cwidth: var(--card-cwidth-xl);
  }
}

@media (min-width: 1528px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-2xl));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-2xl));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-2xl));
    --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-2xl));

    --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-2xl));
    --img-pheight: calc(var(--img-pheight-xl) * var(--scale-2xl));
    --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-2xl));
  }
}

@media (min-width: 1910px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-3xl));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-3xl));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-3xl));
    --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-3xl));

    --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-3xl));
    --img-pheight: calc(var(--img-pheight-xl) * var(--scale-3xl));
    --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-3xl));
  }
}
@media (min-width: 2560px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-4xl));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-4xl));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-4xl));
    --font-size-pheader: calc(var(--font-size-pheader-xl) * var(--scale-4xl));

    --img-pwidth: calc(var(--img-pwidth-xl) * var(--scale-4xl));
    --img-pheight: calc(var(--img-pheight-xl) * var(--scale-4xl));
    --card-cwidth: calc(var(--card-cwidth-xl) * var(--scale-4xl));
  }
}

/* Custom utility classes */
.text-lab-ltitle {
  font-size: var(--font-size-ltitle);
}

.text-lab-ldesc {
  font-size: var(--font-size-ldesc);
}

.text-lab-lbutton {
  font-size: var(--font-size-lbutton);
}
.text-patner-header {
  font-size: var(--font-size-pheader);
}
.patner-img {
  width: var(--img-pwidth);
  height: var(--img-pheight);
}
.shadows .leftShadow,
.shadows .rightShadow {
  position: absolute;
  width: 10rem;
  z-index: 2;
}
.clientsslider .owl-stage {
  transition-timing-function: linear !important;
}
