:root {
  /* Base font sizes for different sections */
  --font-size-ltitle-xl: 18px;
  --font-size-ldesc-xl: 18px;
  --font-size-lbutton-xl: 16px;
  --font-size-lheader-xl: 36px;
  /* Card dimensions */
  --lab-container-width-xl: 39.8rem;
  --card-lwidth-xl: 21rem;
  --card-lheight-xl: 28rem;
  --lab-video-dHeight-xl: 21rem;
  --lab-video-tHeight-xl: 18rem;
  --lab-video-mHeight-xl: 12rem;
  --lab-video-bottom-tile-dHeight-xl: 8.5rem;
  --lab-video-bottom-tile-mHeight-xl: 10rem;

  /* Scaling factors */
  --scale-xxs: 0.8;
  --scale-xs: 1;
  --scale-sm: 1;
  --scale-md: 1;
  --scale-lg: 0.84;
  --scale-2xl: 1.2;
  --scale-3xl: 1.5;
  --scale-4xl: 1.8;
}

/* Media queries for scaling */
/* @media (max-width: 424px) { */
:root {
  --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-xxs));
  --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-xxs));
  --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-xxs));
  --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-xxs));
  --card-lwidth: calc(var(--card-lwidth-xl) * var(--scale-xxs));
  --card-lheight: calc(var(--card-lheight-xl) * 1.1);
  --lab-container-width: "100%";
  --lab-video-height: calc(var(--lab-video-mHeight-xl) * var(--scale-xxs));
  --lab-video-bottom-tile-height: calc(
    var(--lab-video-bottom-tile-mHeight-xl) * var(--scale-xxs)
  );
}
/* } */
@media (min-width: 360px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-xs));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-xs));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-xs));
    --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-xs));
    --card-lwidth: "100%";
    --card-lheight: calc(var(--card-lheight-xl) * 1.3);
    --lab-container-width: "100%";
    --lab-video-height: calc(var(--lab-video-mHeight-xl) * var(--scale-xs));
    --lab-video-bottom-tile-height: calc(
      var(--lab-video-bottom-tile-mHeight-xl) * var(--scale-xs)
    );
  }
}
@media (min-width: 425px) {
  :root {
    --card-lheight: calc(var(--card-lheight-xl) * 1.5);
  }
}
@media (min-width: 500px) {
  :root {
    --card-lheight: calc(var(--card-lheight-xl) * 1.6);
  }
}
@media (min-width: 532px) {
  :root {
    --card-lheight: calc(var(--card-lheight-xl) * 1.9);
  }
}

@media (min-width: 640px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-sm));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-sm));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-sm));
    --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-sm));

    --card-lwidth: calc(var(--card-lwidth-xl) * 0.88);
    --card-lheight: calc(var(--card-lheight-xl) * 0.85);
    --lab-container-width: "100%";
    --lab-video-height: calc(var(--lab-video-tHeight-xl) * var(--scale-sm));
    --lab-video-bottom-tile-height: calc(
      var(--lab-video-bottom-tile-dHeight-xl) * var(--scale-sm)
    );
  }
}
@media (min-width: 700px) {
  :root {
    --card-lwidth: calc(var(--card-lwidth-xl) * 0.95);
    --card-lheight: calc(var(--card-lheight-xl) * 0.95);
  }
}

@media (min-width: 768px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-md));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-md));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-md));
    --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-md));

    --card-lwidth: calc(var(--card-lwidth-xl) * 1.05);
    --card-lheight: calc(var(--card-lheight-xl) * 1.1);
    --lab-container-width: 100%;
    --lab-video-height: calc(var(--lab-video-tHeight-xl) * var(--scale-md));
    --lab-video-bottom-tile-height: calc(
      var(--lab-video-bottom-tile-dHeight-xl) * var(--scale-md)
    );
  }
}
@media (min-width: 896px) {
  :root {
    --card-lwidth: calc(var(--card-lwidth-xl) * 1.22);
    --card-lheight: calc(var(--card-lheight-xl) * 1.23);
  }
}

@media (min-width: 1024px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-lg));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-lg));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-lg));
    --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-lg));

    /* --card-lwidth: calc(var(--card-lwidth-xl) * 0.82); */
    --card-lwidth: 45.8%;
    --card-lheight: calc(var(--card-lheight-xl) * var(--scale-lg));
    /* --lab-container-width: calc(var(--lab-container-width-xl) * 0.91); */
    --lab-container-width: 66%;
    --lab-video-height: calc(var(--lab-video-dHeight-xl) * var(--scale-lg));
    --lab-video-bottom-tile-height: calc(
      var(--lab-video-bottom-tile-dHeight-xl) * var(--scale-lg)
    );
  }
}

@media (min-width: 1280px) {
  :root {
    --font-size-ltitle: var(--font-size-ltitle-xl);
    --font-size-ldesc: var(--font-size-ldesc-xl);
    --font-size-lbutton: var(--font-size-lbutton-xl);
    --font-size-lheader: var(--font-size-lheader-xl);

    /* --card-lwidth: var(--card-lwidth-xl); */

    /* --card-lwidth: 46.5%; */

    --card-lheight: var(--card-lheight-xl);
    /* --lab-container-width: var(--lab-container-width-xl); */
    --lab-video-height: var(--lab-video-dHeight-xl);
    --lab-video-bottom-tile-height: var(--lab-video-bottom-tile-dHeight-xl);
  }
}
@media (min-width: 1408px) {
  :root {
    /* --lab-container-width: calc(var(--lab-container-width-xl) * 1.16); */
    /* --card-lwidth: calc(var(--card-lwidth-xl) * 1.18); */

    --card-lheight: calc(var(--card-lheight-xl) * 1.16);
    --lab-video-height: calc(var(--lab-video-dHeight-xl) * 1.16);
  }
}

@media (min-width: 1528px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-2xl));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-2xl));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-2xl));
    --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-2xl));

    /* --card-lwidth: calc(var(--card-lwidth-xl) * var(--scale-2xl)); */
    --card-lheight: calc(var(--card-lheight-xl) * var(--scale-2xl));
    /* --lab-container-width: calc(
      var(--lab-container-width-xl) * var(--scale-2xl)
    ); */
    --lab-video-height: calc(var(--lab-video-dHeight-xl) * var(--scale-2xl));
    --lab-video-bottom-tile-height: calc(
      var(--lab-video-bottom-tile-dHeight-xl) * var(--scale-2xl)
    );
  }
}
@media (min-width: 1728px) {
  :root {
    /* --lab-container-width: calc(var(--lab-container-width-xl) * 1.35); */
    /* --card-lwidth: calc(var(--card-lwidth-xl) * 1.35); */
    --card-lheight: calc(var(--card-lheight-xl) * 1.35);
    --lab-video-height: calc(var(--lab-video-dHeight-xl) * 1.35);
  }
}
@media (min-width: 1910px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-3xl));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-3xl));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-3xl));
    --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-3xl));

    /* --card-lwidth: calc(var(--card-lwidth-xl) * var(--scale-3xl) * 1.03); */
    --card-lheight: calc(var(--card-lheight-xl) * var(--scale-3xl));
    /* --lab-container-width: calc(
      var(--lab-container-width-xl) * var(--scale-3xl)
    ); */
    --lab-video-height: calc(var(--lab-video-dHeight-xl) * var(--scale-3xl));
    --lab-video-bottom-tile-height: calc(
      var(--lab-video-bottom-tile-dHeight-xl) * var(--scale-3xl)
    );
  }
}
@media (min-width: 2240px) {
  :root {
    /* --lab-container-width: calc(var(--lab-container-width-xl) * 1.65); */
    /* --card-lwidth: calc(var(--card-lwidth-xl) * 1.65); */
    --card-lheight: calc(var(--card-lheight-xl) * 1.65);
    --lab-video-height: calc(var(--lab-video-dHeight-xl) * 1.65);
  }
}
@media (min-width: 2560px) {
  :root {
    --font-size-ltitle: calc(var(--font-size-ltitle-xl) * var(--scale-4xl));
    --font-size-ldesc: calc(var(--font-size-ldesc-xl) * var(--scale-4xl));
    --font-size-lbutton: calc(var(--font-size-lbutton-xl) * var(--scale-4xl));
    --font-size-lheader: calc(var(--font-size-lheader-xl) * var(--scale-4xl));

    /* --card-lwidth: calc(var(--card-lwidth-xl) * var(--scale-4xl) * 1.04); */
    --card-lheight: calc(var(--card-lheight-xl) * var(--scale-4xl));
    /* --lab-container-width: calc(
      var(--lab-container-width-xl) * var(--scale-4xl)
    ); */
    --lab-video-height: calc(var(--lab-video-dHeight-xl) * var(--scale-4xl));
    --lab-video-bottom-tile-height: calc(
      var(--lab-video-bottom-tile-dHeight-xl) * var(--scale-4xl)
    );
  }
}

/* Custom utility classes */
.text-lab-ltitle {
  font-size: var(--font-size-ltitle);
}

.text-lab-ldesc {
  font-size: var(--font-size-ldesc);
}

.text-lab-lbutton {
  font-size: var(--font-size-lbutton);
}
.text-lab-lheader {
  font-size: var(--font-size-lheader);
}
.lab-card {
  width: var(--card-lwidth);
  height: var(--card-lheight);
}
.lab-card-child {
  height: var(--card-lheight);
}
.lab-c-width {
  width: var(--lab-container-width);
}
.lab-video-height {
  height: var(--lab-video-height);
}
.lab-video-bottom-tile-height {
  height: var(--lab-video-bottom-tile-height);
}
.word-break {
  word-break: break-word;
}
.list-disc {
  list-style-type: disc;
}
