/* Header component css goes here, all the classnames shoud start from .header-yourclassname */
.mobile-menu-h {
  height: calc(100dvh - 3.1rem);
  height: -webkit-calc(100dvh - 3.1rem);
}
#mobile-menu {
  /* background-image: url("/public/images/d-menu.png"); */
  background-repeat: no-repeat;
  width: 100%;
  float: right;
  background-position: right;
  background-position-y: bottom;
}

/* .header-service-dropdown::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  z-index: -1;
} */
