@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
  font-size: 12.8;
}
@media screen and (min-width: 360px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1528px) {
  html {
    font-size: 19.2px;
  }
}
@media screen and (min-width: 1910px) {
  html {
    font-size: 24px;
  }
}
@media screen and (min-width: 2560px) {
  html {
    font-size: 28.8px;
  }
} */
body {
  background-color: #1a1a1a;
  font-family: "Noto Sans", sans-serif;
  overflow-x: hidden;
  overflow-y: auto !important;
}
#root {
  overflow-x: hidden;
}
.heading-dela h1,
.heading-dela h2,
.heading-dela h3,
.heading-dela h4,
.heading-dela h5,
.heading-dela h6 {
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
  text-transform: uppercase !important;
}

.heading-noto-bold h1,
.heading-noto-bold h2,
.heading-noto-bold h3,
.heading-noto-bold h4,
.heading-noto-bold h5,
.heading-noto-bold h6 {
  font-family: "Noto Sans", sans-serif;
  text-transform: uppercase !important;
  font-weight: 800;
  line-height: 100% !important;
}
.font-dela {
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
}
.heading-dela .font-dela,
.heading-dela .font-dela,
.heading-dela .font-dela,
.heading-dela .font-dela,
.heading-dela .font-dela,
.heading-dela .font-dela {
  font-family: "Dela Gothic One", sans-serif;

  font-weight: 400;
}
.font-dela-gothic {
  font-family: "Dela Gothic One", sans-serif !important;
}
.heading-noto-bold .font-dela,
.heading-noto-bold .font-dela,
.heading-noto-bold .font-dela,
.heading-noto-bold .font-dela,
.heading-noto-bold .font-dela,
.heading-noto-bold .font-dela {
  font-family: "Noto Sans", sans-serif;

  font-weight: 800;
  line-height: 100% !important;
}

.slant-top-left {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 10%);
}
.slant-bottom-right {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 90% 100%, 0 100%);
}
.slant-bottom-right-footer {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 95% 100%, 0 100%);
}
.hover-slant-br:hover {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
}
.slant-btn-bottom {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
}
.btn-white-wrapper {
  display: inline-block;
  filter: drop-shadow(0px 2px 0px #111) drop-shadow(2px 0px 0px #111)
    drop-shadow(-2px 0px 0px #111) drop-shadow(0px -2px 0px #111);
}
.btn-white {
  display: inline-block;
  clip-path: polygon(0% 0%, 100% 0, 100% 65%, 85% 100%, 0 100%, 0% 50%);
  background: #cdcdcd;
  border: 0;
}
.btn-white-nav {
  display: inline-block;
  clip-path: polygon(25% 0%, 100% 0, 100% 100%, 75% 100%, 0 100%, 0% 50%);
  background: #cdcdcd;
  border: 0;
}
.slant-top-left-map {
  clip-path: polygon(0.4rem 0, 100% 0, 100% 100%, 0 100%, 0 0.4rem);
}
@media screen and (min-width: 1280px) {
  .slant-top-left-map {
    clip-path: polygon(0.5rem 0, 100% 0, 100% 100%, 0 100%, 0 0.5rem);
  }
}
@media screen and (min-width: 1528px) {
  .slant-top-left-map {
    clip-path: polygon(0.6rem 0, 100% 0, 100% 100%, 0 100%, 0 0.6rem);
  }
}

@media screen and (min-width: 1910px) {
  .slant-top-left-map {
    clip-path: polygon(0.7rem 0, 100% 0, 100% 100%, 0 100%, 0 0.7rem);
  }
}

.bottom-right-slant-map {
  clip-path: polygon(
    calc(100% - 0.5rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 0.5rem)
  );
}
@media screen and (min-width: 1280px) {
  .bottom-right-slant-map {
    clip-path: polygon(
      calc(100% - 0.6rem) 100%,
      0 100%,
      0 0,
      100% 0,
      100% calc(100% - 0.6rem)
    );
  }
}
@media screen and (min-width: 1910px) {
  .bottom-right-slant-map {
    clip-path: polygon(
      calc(100% - 0.8rem) 100%,
      0 100%,
      0 0,
      100% 0,
      100% calc(100% - 0.8rem)
    );
  }
}
@media screen and (max-width: 768px) {
  .slant-top-footer {
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 100% 100%, 0 100%, 0% 8%);
  }
}
.slant-bottom-footer {
  clip-path: polygon(0 0, 100% 0, 100% 97%, 91% 100%, 0 100%);
}
@media screen and (min-width: 769px) {
  .slant-bottom-footer {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 95% 100%, 0 100%) !important;
  }
}
.slant-bottom-right-lang {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%);
}
.contact-mobile {
  display: inline-block;
  clip-path: polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0 100%, 0% 50%);
  background: #000;
  border: 0;
}
.btn-mobile-wrapper {
  display: inline-block;
  filter: drop-shadow(0px 2px 0px #fff) drop-shadow(2px 0px 0px #fff)
    drop-shadow(-2px 0px 0px #fff) drop-shadow(0px -2px 0px #fff);
}
.container {
  width: 94%;
  max-width: 424px; /* 94% of 425px */
  margin-left: auto;
  margin-right: auto;
}
.ourservices-container {
  max-width: calc(100% - (100% - 94%) / 2);
  margin-left: auto;
}

@media (min-width: 425px) {
  .container {
    max-width: 94%;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 88%; /* 88% of 640px */
  }
  .ourservices-container {
    max-width: calc(100% - (100% - 88%) / 2);
  }

  .slant-bottom-footer {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 93% 100%, 0 100%);
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 88%; /* 88% of 768px */
  }
  .ourservices-container {
    max-width: calc(100% - (100% - 88%) / 2);
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 85%; /* 84% of 1024px */
  }
  .ourservices-container {
    max-width: calc(100% - (100% - 85%) / 2);
  }
}

.no-scroll {
  overflow: hidden !important;
  position: fixed !important;
}

/* Project Banner Animation */
@keyframes zoomInAnimationProjectDescription {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}

.zoom-animate-projectDescription {
  animation: zoomInAnimationProjectDescription 1s ease-in-out;
}

/* @media (min-width: 1280px) {
  .container {
    max-width: 84%; 
  }
  .ourservices-container {
    max-width: calc(100% - (100% - 84%) / 2);
  }
}

@media (min-width: 1528px) {
  .container {
    max-width: 84%;
  }
  .ourservices-container {
    max-width: calc(100% - (100% - 84%) / 2);
  }
}

@media (min-width: 1910px) {
  .container {
    max-width: 84%; 
  }
  .ourservices-container {
    max-width: calc(100% - (100% - 84%) / 2);
  }
}
@media (min-width: 2560px) {
  .container {
    max-width: 84%;
  }
  .ourservices-container {
    max-width: calc(100% - (100% - 84%) / 2);
  }
} */
.focus-0:focus {
  outline: none;
  box-shadow: none;
  -webkit-focus-ring-color: transparent;
}
.cms-style img {
  width: auto;
  margin: 0 auto;
}
.cms-style iframe {
  width: 100%;
  height: 500px;
}
.cms-style table {
  border-collapse: collapse;
  width: 100%;
  line-height: 1;
}
.cms-style th,
td {
  border: 2px solid #61e063;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}
.cms-style ul {
  list-style-type: square;
  padding-left: 1.5rem;
  line-height: 1;
  white-space: pre-line;
}
.cms-style ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  line-height: 1;
  white-space: pre-line;
}
.cms-style a {
  /* color: #61e063; */
  text-decoration: underline;
}
.cms-style h4 {
  font-size: 20px;
  /* text-transform: capitalize !important; */
}
.cms-style h4 {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 3rem;
}
.cms-style h4 img,
.cms-style li img {
  margin: 0 !important;
}
/* .cms-style li {
  display: flex;
  padding-left: 0;
  align-items: center;
  position: relative;
}
.cms-style li strong::before {
  content: "*";
  list-style-type: square;
  position: absolute;
  margin-right: 1rem;
}
.cms-style li strong {
  width: 100%;
  padding-left: 1.5rem;
}
.cms-style ul {
  padding-left: 0 !important;
} */
.whitespace-normal {
  white-space: normal !important;
}
.letter-caps:first-letter {
  text-transform: uppercase;
}
