/* newslist Page css goes here, all the classnames shoud start from .newslist-yourclassname */
.videos-btn:hover::before {
  content: "";
  width: 22px;
  height: 26px;
  background: #1a1a1a;
  border: 3px solid #ff5dd2;
  transform: rotate(215deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  top: 40px;
  right: -13px;
}
.news-d {
  background-image: url("/public/images/news-d.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  padding: 1rem 0;
}

.img-part {
  clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0 100%, 0 3.5rem);
}
.news-hover-slant-parent:hover .news-hover-slant {
  clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0 100%, 0 3.5rem);
}
.list-decimal li::marker {
  font-weight: bold;
}
