:root {
  /* Base font sizes for different sections */

  --font-size-mHeader-xl: 36px;
  --font-size-mapButton-xl: 18px;
  /* Card dimensions */
  --map-width-xl: 72rem;
  --map-height-xl: 42.3rem;
  --mHeader-width-xl: 45rem;
  --mHeader-height-xl: 6.7rem;

  /* Scaling factors */

  --scale-lg: 0.84;
  --scale-2xl: 1.2;
  --scale-3xl: 1.5;
  --scale-4xl: 1.8;
}

/* Media queries for scaling */
/* @media (max-width: 424px) { */

@media (min-width: 1024px) {
  :root {
    --font-size-mHeader: calc(var(--font-size-mHeader-xl) * 1);
    --font-size-mapButton: calc(var(--font-size-mapButton-xl) * 1);

    --map-width: 56rem;
    --map-height: 38rem;
    --mHeader-width: calc(var(--mHeader-width-xl) * var(--scale-lg));
    --mHeader-height: calc(var(--mHeader-height-xl) * var(--scale-lg));
  }
}

@media (min-width: 1280px) {
  :root {
    --font-size-mHeader: var(--font-size-mHeader-xl);
    --font-size-mapButton: var(--font-size-mapButton-xl);

    --map-width: var(--map-width-xl);
    --map-height: var(--map-height-xl);
    --mHeader-width: var(--mHeader-width-xl);
    --mHeader-height: var(--mHeader-height-xl);
  }
}
/*
@media (min-width: 1408px) {
  :root {
    --map-width: calc(var(--map-width-xl) * 1.15);
  }
} */
@media (min-width: 1528px) {
  :root {
    --font-size-mHeader: calc(var(--font-size-mHeader-xl) * var(--scale-2xl));
    --font-size-mapButton: calc(
      var(--font-size-mapButton-xl) * var(--scale-2xl)
    );

    --map-width: calc(var(--map-width-xl) * var(--scale-2xl));
    --map-height: calc(var(--map-height-xl) * var(--scale-2xl));
    --mHeader-width: calc(var(--mHeader-width-xl) * var(--scale-2xl));
    --mHeader-height: calc(var(--mHeader-height-xl) * var(--scale-2xl));
  }
}
/* @media (min-width: 1728px) {
  :root {
    --map-width: calc(var(--map-width-xl) * 1.35);
  }
} */
@media (min-width: 1910px) {
  :root {
    --font-size-mHeader: calc(var(--font-size-mHeader-xl) * var(--scale-3xl));
    --font-size-mapButton: calc(
      var(--font-size-mapButton-xl) * var(--scale-3xl)
    );

    --map-width: calc(var(--map-width-xl) * var(--scale-3xl));
    --map-height: calc(var(--map-height-xl) * var(--scale-3xl));
    --mHeader-width: calc(var(--mHeader-width-xl) * var(--scale-3xl));
    --mHeader-height: calc(var(--mHeader-height-xl) * var(--scale-3xl));
  }
}
/* @media (min-width: 2240px) {
  :root {
    --map-width: calc(var(--map-width-xl) * 1.65);
  }
} */
@media (min-width: 2560px) {
  :root {
    --font-size-mHeader: calc(var(--font-size-mHeader-xl) * var(--scale-4xl));
    --font-size-mapButton: calc(
      var(--font-size-mapButton-xl) * var(--scale-4xl)
    );

    --map-width: calc(var(--map-width-xl) * var(--scale-4xl) * 1.09);
    --map-height: calc(var(--map-height-xl) * var(--scale-4xl));
    --mHeader-width: calc(var(--mHeader-width-xl) * var(--scale-4xl));
    --mHeader-height: calc(var(--mHeader-height-xl) * var(--scale-4xl));
  }
}

/* Custom utility classes */

.text-map-header {
  font-size: var(--font-size-mHeader);
}
.text-map-button {
  font-size: var(--font-size-mapButton);
}
.map-size {
  width: var(--map-width);
  /* height: var(--map-height); */
}
.map-header-size {
  width: var(--mHeader-width);
  height: var(--mHeader-height);
}
.map-btn-top-slant:hover {
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 0 100%, 0 31%);
}
.map-popup-top-slant {
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 0 100%, 0 31%);
}
.get-btn:hover::before {
  content: "";
  width: 21px;
  height: 20px;
  background: #1a1a1a;
  border: 3px solid #2ad9ff;
  transform: rotate(215deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  top: 40px;
  right: -13px;
}
@media (min-width: 2560px) {
  .get-btn:hover::before {
    top: 47px;
  }
}
.join-btn-hover:hover {
  clip-path: polygon(12% 0, 100% 0, 100% 100%, 0 100%, 0 35%);
}

.test-slant {
  clip-path: polygon(0 0, 100% 0, 100% 60%, 80% 100%, 0 100%, 0 40%);
  outline: 8px solid red;
}
/* .slanted-button {
  position: relative;
  background: transparent;
  color: black;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
} */
