/* services Page css goes here, all the classnames shoud start from .services-yourclassname */
/* top banner css */
.top-banner-top-left-slant {
  clip-path: polygon(2.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3.5rem);
}
@media (min-width: 640px) {
  .top-banner-top-left-slant {
    clip-path: polygon(3.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3.5rem);
  }
}
@media (min-width: 1024px) {
  .top-banner-top-left-slant {
    clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem);
  }
}
@media (min-width: 1530px) {
  .top-banner-top-left-slant {
    clip-path: polygon(4rem 0, 100% 0, 100% 100%, 0 100%, 0 4rem);
  }
}

.services-btn:hover {
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 0 100%, 0 28%);
}
.audio-production-bg-1 {
  background-image: url("/public/images/audio-production-bg-1.png");
  background-repeat: no-repeat;
  background-position: top right;
}
.para-btn-slant-hvr:hover {
  clip-path: polygon(0 0, 100% 0, 100% 2rem, 94% 100%, 0 100%);
}
/* .glance-card{
    padding: 3rem;
    height: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(150, 71, 255);
    position: relative;
    margin-bottom: 1rem;
    -webkit-clip-path: polygon(0% 0%, 80% 0%, 100% 20%, 100% 0%, 100% 100%, 0% 100%, 0% 100%, 0% 0%, 100% 100%);
    clip-path: polygon(0% 0%, 80% 0%, 100% 20%, 100% 0%, 100% 100%, 0% 100%, 0% 100%, 0% 0%, 100% 100%);
} */

@media (min-width: 640px) {
  .audio-production-bg-2 {
    background-image: url("/public/images/audio-production-bg-2.png");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
  }
  .para-btn-slant-hvr:hover {
    clip-path: polygon(0 0, 100% 0, 100% 2.2rem, 90% 100%, 0 100%);
  }
}
@media (min-width: 2560px) {
  .para-btn-slant-hvr:hover {
    clip-path: polygon(0 0, 100% 0, 100% 2.8rem, 90% 100%, 0 100%);
  }
}

.codev-top-left-slant {
  clip-path: polygon(2rem 0, 100% 0, 100% 100%, 0 100%, 0 2.5rem);
}
.codev-btn-slant {
  clip-path: polygon(
    calc(100% - 1.7rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 1.1rem)
  );
}

.codev-btn-slant-hvr:hover {
  clip-path: polygon(
    calc(100% - 1.7rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 1.1rem)
  );
}

@media (min-width: 1024px) {
  .codev-top-left-slant {
    clip-path: polygon(3.5rem 0, 100% 0, 100% 100%, 0 100%, 0 4rem);
  }
}

@media (min-width: 1528px) {
  .codev-top-left-slant {
    clip-path: polygon(4rem 0, 100% 0, 100% 100%, 0 100%, 0 4.8rem);
  }
}
.service-youtube-video iframe {
  border-radius: 10px;
}

.art-production-bg-1 {
  background-position: 100% -13rem;
}

.player-support-bg-1 {
  background-position: 110% -2rem;
  background-size: 90%;
}
@media (min-width: 640px) {
  .player-support-bg-1 {
    background-position: 100% -8rem;
    background-size: 94%;
  }
}
@media (min-width: 768px) {
  .player-support-bg-1 {
    background-position: 100% -8rem;
    background-size: 75%;
  }
}
@media (min-width: 1024px) {
  .player-support-bg-1 {
    background-position: 100% 0rem;
    background-size: 55%;
  }
}
@media (min-width: 1528px) {
  .player-support-bg-1 {
    background-position: 100% 0rem;
    background-size: 50%;
  }
}
.audio-production-bg-2 {
  background-size: 0;
}
@media (min-width: 640px) {
  .audio-production-bg-2 {
    background-position: -2rem -7rem;
    background-size: 100%;
  }
}
@media (min-width: 1024px) {
  .audio-production-bg-2 {
    background-position: 0 36rem;
    background-size: 70% 60%;
  }
}
@media (min-width: 1728px) {
  .audio-production-bg-2 {
    background-position: 0 48rem;
    background-size: 60% 55%;
  }
}
.audio-production-bg-1 {
  background-position: 100% 0rem;
  background-size: auto 40%;
}
@media (min-width: 640px) {
  .audio-production-bg-1 {
    background-position: 100% 16rem;
    background-size: auto 30%;
  }
}
@media (min-width: 1024px) {
  .audio-production-bg-1 {
    background-position: 100% 20rem;
    background-size: auto 42%;
  }
}
@media (min-width: 1728px) {
  .audio-production-bg-1 {
    background-position: 100% 24rem;

    background-size: auto 44%;
  }
}
.game-development-bg-1 {
  background-position: 100% 1rem;
  background-size: auto 36%;
}
@media (min-width: 640px) {
  .game-development-bg-1 {
    background-position: 100% 0;
    background-size: auto 38%;
  }
}
@media (min-width: 1024px) {
  .game-development-bg-1 {
    background-position: 100% 15rem;
    background-size: auto 75%;
  }
}
@media (min-width: 1728px) {
  .game-development-bg-1 {
    background-position: 100% 17rem;

    background-size: auto 80%;
  }
}
.game-development-bg-2 {
  background-size: 0;
}
@media (min-width: 640px) {
  .game-development-bg-2 {
    background-position: 100% 0;
    background-size: auto 38%;
  }
}
@media (min-width: 1024px) {
  .game-development-bg-3 {
    background-position: 100% 15rem;
    background-size: auto 75%;
  }
}
@media (min-width: 1728px) {
  .game-development-bg-4 {
    background-position: 100% 17rem;

    background-size: auto 80%;
  }
}
.square-bullets ul {
  list-style-type: square;
  padding-left: 1.5rem;
}
.services-slide-scroll::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  width: 6px;
  border-radius: 4px;
}

.services-slide-scroll::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
}

.services-slide-scroll::-webkit-scrollbar-thumb {
  background: #1a1a1a; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
  border-radius: 4px;
}

.services-slide-scroll::-webkit-scrollbar-thumb:hover {
  background: #1a1a1a; /* Color of the thumb when hovered */
}
.ourservices-container {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Enable momentum scrolling on iOS */
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.scroll-container {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
