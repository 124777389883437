:root {
  /* Base font sizes for different sections */

  --weBelieve-dHeight-xl: 23.25rem;
  /* --weBelieve-width-xl: 43rem; */
  --weBelieve-mHeight-xl: 40rem;
  --weBelieve-tHeight-xl: 25.25rem;

  /* Scaling factors */
  --scale-xxs: 0.8;
  --scale-xs: 1;
  --scale-sm: 1;
  --scale-md: 1;
  --scale-lg: 0.84;
  --scale-2xl: 1.2;
  --scale-3xl: 1.5;
  --scale-4xl: 1.8;
}

/* Media queries for scaling */
/* @media (max-width: 424px) { */
:root {
  --weBelieve-height: "auto";
}
@media (min-width: 425px) {
  :root {
    --weBelieve-height: "auto";
  }
}

@media (min-width: 640px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-tHeight-xl) * var(--scale-sm));
  }
}

@media (min-width: 768px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-tHeight-xl) * var(--scale-md));
  }
}

@media (min-width: 1024px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-dHeight-xl) * 1);
  }
}

@media (min-width: 1280px) {
  :root {
    --weBelieve-height: var(--weBelieve-dHeight-xl);
  }
}
@media (min-width: 1408px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-dHeight-xl) * 1.16);
  }
}
@media (min-width: 1528px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-dHeight-xl) * var(--scale-2xl));
  }
}

@media (min-width: 1728px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-dHeight-xl) * 1.35);
  }
}
@media (min-width: 1910px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-dHeight-xl) * var(--scale-3xl));
  }
}
@media (min-width: 2240px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-dHeight-xl) * 1.35);
  }
}
@media (min-width: 2560px) {
  :root {
    --weBelieve-height: calc(var(--weBelieve-dHeight-xl) * var(--scale-4xl));
  }
}

/* Custom utility classes */

.we-believe-height {
  height: var(--weBelieve-height);
}
