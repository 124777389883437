:root {
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-30: 30px;
  --font-size-32: 32px;
  --font-size-36: 36px;
  --font-size-45: 45px;
  --font-size-48: 48px;
  --font-size-60: 60px;
  --font-size-72: 72px;

  --scaleN-xxs: 0.8;
  --scaleN-xs: 1;
  --scaleN-sm: 0.9;
  --scaleN-md: 1;
  --scaleN-lg: 0.9;
  --scaleN-xl: 1;
  /* 
  --scaleN-xl2: 1.05;
  --scaleN-2xl: 1.1;
  --scaleN-2xl2: 1.17;
--scaleN-3xl: 1.25;
  --scaleN-3xl2: 1.37;
  --scaleN-4xl: 1.5; */

  --scaleN-xl2: 1.1;
  --scaleN-2xl: 1.2;
  --scaleN-2xl2: 1.35;
  --scaleN-3xl: 1.5;
  --scaleN-3xl2: 1.65;
  --scaleN-4xl: 1.8;
}

:root {
  --font-size-45-48-72: calc(var(--font-size-45) * var(--scaleN-xxs));
  --font-size-36-48-72: calc(var(--font-size-36) * var(--scaleN-xxs));
  --font-size-30-36-60: calc(var(--font-size-30) * var(--scaleN-xxs));
  --font-size-36-36-48: calc(var(--font-size-36) * var(--scaleN-xxs));
  --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-xxs));
  --font-size-24-36-36: calc(var(--font-size-24) * var(--scaleN-xxs));
  --font-size-18-18-24: calc(var(--font-size-18) * var(--scaleN-xxs));
  --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-xxs));
  --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-xxs));
  --font-size-18-20-20: calc(var(--font-size-18) * var(--scaleN-xxs));
}

@media (min-width: 360px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-45) * var(--scaleN-xs));
    --font-size-36-48-72: calc(var(--font-size-36) * var(--scaleN-xs));
    --font-size-30-36-60: calc(var(--font-size-30) * var(--scaleN-xs));

    --font-size-36-36-48: calc(var(--font-size-36) * var(--scaleN-xs));
    --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-xs));

    --font-size-24-36-36: calc(var(--font-size-24) * var(--scaleN-xs));
    --font-size-18-18-24: calc(var(--font-size-18) * var(--scaleN-xs));

    --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-xs));
    --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-xs));

    --font-size-18-20-20: calc(var(--font-size-18) * var(--scaleN-xs));
  }
}

@media (min-width: 640px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-48) * var(--scaleN-sm));
    --font-size-36-48-72: calc(var(--font-size-48) * var(--scaleN-sm));
    --font-size-30-36-60: calc(var(--font-size-36) * var(--scaleN-sm));

    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-sm));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-sm));
  }
}

@media (min-width: 768px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-48) * var(--scaleN-md));
    --font-size-36-48-72: calc(var(--font-size-48) * var(--scaleN-md));
    --font-size-30-36-60: calc(var(--font-size-36) * var(--scaleN-md));

    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-md));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-md));
  }
}

@media (min-width: 1024px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-lg));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-lg));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-lg));

    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-lg));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-lg));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-lg));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-lg));
  }
}

@media (min-width: 1280px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-xl));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-xl));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-xl));

    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-xl));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-xl));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-xl));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-xl));
  }
}
@media (min-width: 1408px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-xl2));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-xl2));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-xl2));

    --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-xl2));

    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-xl2));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-xl2));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-xl2));
    --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-xl2));
    --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-xl2));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-xl2));
  }
}

@media (min-width: 1528px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-2xl));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-2xl));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-2xl));

    --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-2xl));

    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-2xl));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-2xl));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-2xl));

    --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-2xl));
    --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-2xl));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-2xl));
  }
}
@media (min-width: 1728px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-2xl2));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-2xl2));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-2xl2));

    --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-2xl2));

    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-2xl2));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-2xl2));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-2xl2));

    --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-2xl2));
    --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-2xl2));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-2xl2));
  }
}
@media (min-width: 1910px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-3xl));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-3xl));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-3xl));

    --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-3xl));

    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-3xl));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-3xl));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-3xl));

    --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-3xl));
    --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-3xl));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-3xl));
  }
}
@media (min-width: 2240px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-3xl2));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-3xl2));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-3xl2));

    --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-3xl2));

    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-3xl2));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-3xl2));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-3xl2));

    --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-3xl2));
    --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-3xl2));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-3xl2));
  }
}
@media (min-width: 2560px) {
  :root {
    --font-size-45-48-72: calc(var(--font-size-72) * var(--scaleN-4xl));
    --font-size-36-48-72: calc(var(--font-size-72) * var(--scaleN-4xl));
    --font-size-30-36-60: calc(var(--font-size-60) * var(--scaleN-4xl));

    --font-size-36-36-36: calc(var(--font-size-36) * var(--scaleN-4xl));
    --font-size-36-36-48: calc(var(--font-size-48) * var(--scaleN-4xl));
    --font-size-24-36-36: calc(var(--font-size-36) * var(--scaleN-4xl));
    --font-size-18-18-24: calc(var(--font-size-24) * var(--scaleN-4xl));
    --font-size-18-18-18: calc(var(--font-size-18) * var(--scaleN-4xl));
    --font-size-16-16-16: calc(var(--font-size-16) * var(--scaleN-4xl));
    --font-size-18-20-20: calc(var(--font-size-20) * var(--scaleN-4xl));
  }
}

/*--------------------- font size classes------------- */
.font-45-48-72 {
  font-size: var(--font-size-45-48-72) !important ;
}
.font-36-48-72 {
  font-size: var(--font-size-36-48-72) !important ;
}
.font-30-36-60 {
  font-size: var(--font-size-36-48-72) !important ;
}
.font-36-36-36 {
  font-size: var(--font-size-36-36-36) !important ;
}
.font-36-36-48 {
  font-size: var(--font-size-36-36-48) !important ;
}
.font-24-36-36 {
  font-size: var(--font-size-24-36-36) !important ;
}
.font-18-18-24 {
  font-size: var(--font-size-18-18-24) !important;
}
.font-18-18-18 {
  font-size: var(--font-size-18-18-18) !important;
}
.font-16-16-16 {
  font-size: var(--font-size-16-16-16) !important;
}
.font-18-20-20 {
  font-size: var(--font-size-18-20-20) !important;
}
