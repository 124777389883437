/* Home page css goes here, all the classnames shoud start from .home-yourclassname */

/* Top banner css starts here */
.banner-bg {
  background: linear-gradient(0deg, #1a1a1a66 0%, #1a1a1a66 100%), #1a1a1a;
}
/* Events and news css*/
.news-events-div:hover .clild-slant {
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 21%);
}
/* .clild-slant {
  clip-path: polygon(14% 0, 100% 0, 100% 100%, 0 100%, 0 21%);
} */
.news-events-slant {
  clip-path: polygon(14% 0, 100% 0, 100% 100%, 0 100%, 0 21%);
}
.events-view-all:hover {
  clip-path: polygon(0 0, 100% 0, 100% 75%, 90% 100%, 0 100%);
}
.hide-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.our-services-slant {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 85% 100%, 0 100%);
}
.our-services-div:hover {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 85% 100%, 0 100%);
}
.blue-div {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
}

@media (min-width: 320px) {
  .project-show-img-clip {
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 15%);
  }
}
@media (min-width: 1024px) {
  .project-show-img-clip {
    clip-path: none;
  }
}
.home-rhombus {
  height: 20px;
  aspect-ratio: 1;
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  background: #68ffd2;
}
.home-bg-banner {
  background-image: url("/public/images/home-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.we-believe-clip {
  clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0 100%, 0 4rem);
}

.bg-d-orange {
  /* background-image: url("/public/images/d-orange.png"); */
  background-repeat: no-repeat;
  width: 100%;
  float: right;
  background-position: right;
  background-position-y: top;
}
.bg-d-green {
  /* background-image: url("/public/images/d-green.png"); */
  background-repeat: no-repeat;
  width: 100%;
  /* float: right; */
  background-position: left;
  background-position-y: bottom;
  margin-bottom: 5rem;
}
.last-services:last-child {
  margin-right: 5rem;
}
.h-home {
  height: calc(100vh / 16px * 1rem);
}
.projects-slant:hover .projects-slant-child {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
}
.homepage-top-youtube iframe {
  border-radius: 10px;
}
.ourservices-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
/* .ourServices-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 80%;
}
.ourServices-scrollbar::-webkit-scrollbar-track:horizontal {
  background: #46464680;
  width: 10px;
}
.ourServices-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: #ff8200;
  width: 10px;

}
.ourServices-scrollbar::-webkit-scrollbar-thumb:horizontal:hover {
  background: #ff8200;
} */
.scroll-track {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px; /* Track height */
  background: #46464680; /* Track color */
}
.scroll-thumb {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6px; /* Thumb height */
}
