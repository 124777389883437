/* contact Page css goes here, all the classnames shoud start from .contact-yourclassname */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-transition: background-color 0s 600000s, color 0s 600000s !important;
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
/* Custom scrollbar styles for .custom-scroll class */
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #086499;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
