:root {
  /* Base font sizes for different setions */
  --font-size-deskMainHeader-xl: 72px;
  --font-size-mobileMainHeader-xl: 45px;
  --font-size-topBannerText-xl: 18px;
  --font-size-para-xl: 18px;
  --font-size-button-xl: 18px;
  --font-size-button-secondary-xl: 16px;
  --font-size-main-xl: 36px;
  --font-size-48: 48px;

  /* Scaling factors */
  --scale-xxs: 0.8;
  --scale-xs: 1;
  --scale-sm: 1;
  --scale-md: 1;
  --scale-lg: 1;
  --scale-2xl: 1.2;
  --scale-3xl: 1.5;
  --scale-4xl: 1.8;
}

:root {
  --font-size-deskMainHeader: calc(
    var(--font-size-mobileMainHeader-xl) * var(--scale-xxs)
  );
  --font-size-topBannerText: calc(
    var(--font-size-topBannerText-xl) * var(--scale-xxs)
  );
  --font-size-para: calc(var(--font-size-para-xl) * var(--scale-xxs));
  --font-size-main: calc(var(--font-size-main-xl) * var(--scale-xxs));
  --font-size-48: calc(var(--font-size-48-xl) * var(--scale-xxs));
  --font-size-button: calc(var(--font-size-button-xl) * var(--scale-xxs));
  --font-size-button-secondary: calc(
    var(--font-size-button-secondary-xl) * var(--scale-xxs)
  );
}
/* } */
@media (min-width: 360px) {
  :root {
    --font-size-deskMainHeader: calc(
      var(--font-size-mobileMainHeader-xl) * var(--scale-xs)
    );
    --font-size-topBannerText: calc(
      var(--font-size-topBannerText-xl) * var(--scale-xs)
    );
    --font-size-para: calc(var(--font-size-para-xl) * var(--scale-xs));
    --font-size-main: calc(var(--font-size-main-xl) * var(--scale-xs));
    --font-size-48: calc(var(--font-size-48-xl) * var(--scale-xs));
    --font-size-button: calc(var(--font-size-button-xl) * var(--scale-xs));
    --font-size-button-secondary: calc(
      var(--font-size-button-secondary-xl) * var(--scale-xs)
    );
  }
}

@media (min-width: 640px) {
  :root {
    --font-size-deskMainHeader: calc(
      var(--font-size-mobileMainHeader-xl) * var(--scale-sm)
    );
    --font-size-topBannerText: calc(
      var(--font-size-topBannerText-xl) * var(--scale-sm)
    );
    --font-size-para: calc(var(--font-size-para-xl) * var(--scale-sm));
    --font-size-main: calc(var(--font-size-main-xl) * var(--scale-sm));
    --font-size-48: calc(var(--font-size-48-xl) * var(--scale-sm));
    --font-size-button: calc(var(--font-size-button-xl) * var(--scale-sm));
    --font-size-button-secondary: calc(
      var(--font-size-button-secondary-xl) * var(--scale-sm)
    );
  }
  /* .about-ul ul {
    position: relative;
  }
  .about-ul ul::after {
    content: "";
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    width: 1px;
    background-color: red;
  } */
}

@media (min-width: 768px) {
  :root {
    --font-size-deskMainHeader: calc(
      var(--font-size-mobileMainHeader-xl) * var(--scale-md)
    );
    --font-size-topBannerText: calc(
      var(--font-size-topBannerText-xl) * var(--scale-md)
    );
    --font-size-para: calc(var(--font-size-para-xl) * var(--scale-md));
    --font-size-main: calc(var(--font-size-main-xl) * var(--scale-md));
    --font-size-48: calc(var(--font-size-48-xl) * var(--scale-md));
    --font-size-button: calc(var(--font-size-button-xl) * var(--scale-md));
    --font-size-button-secondary: calc(
      var(--font-size-button-secondary-xl) * var(--scale-md)
    );
  }
  /* .about-ul ul::after {
    right: -20px;
  } */
}

@media (min-width: 1024px) {
  :root {
    --font-size-deskMainHeader: calc(
      var(--font-size-deskMainHeader-xl) * var(--scale-lg)
    );
    --font-size-topBannerText: calc(
      var(--font-size-topBannerText-xl) * var(--scale-lg)
    );
    --font-size-para: calc(var(--font-size-para-xl) * 1);
    --font-size-main: calc(var(--font-size-main-xl) * 0.8);
    --font-size-48: calc(var(--font-size-48-xl) * var(--scale-lg));
    --font-size-button: calc(var(--font-size-button-xl) * var(--scale-lg));
    --font-size-button-secondary: calc(
      var(--font-size-button-secondary-xl) * var(--scale-lg)
    );
  }
  /* .about-ul ul::after {
    right: 20px;
  } */
}

@media (min-width: 1280px) {
  :root {
    --font-size-deskMainHeader: var(--font-size-deskMainHeader-xl);
    --font-size-topBannerText: var(--font-size-topBannerText-xl);
    --font-size-para: var(--font-size-para-xl);
    --font-size-main: var(--font-size-main-xl);
    --font-size-48: var(--font-size-48-xl) *;
    --font-size-button: var(--font-size-button-xl);
    --font-size-button-secondary: var(--font-size-button-secondary-xl);
  }
}
@media (min-width: 1408px) {
  :root {
    --font-size-deskMainHeader: calc(var(--font-size-deskMainHeader-xl) * 1.1);
  }
}

@media (min-width: 1528px) {
  :root {
    --font-size-deskMainHeader: calc(
      var(--font-size-deskMainHeader-xl) * var(--scale-2xl)
    );
    --font-size-topBannerText: calc(
      var(--font-size-topBannerText-xl) * var(--scale-2xl)
    );
    --font-size-para: calc(var(--font-size-para-xl) * var(--scale-2xl));
    --font-size-main: calc(var(--font-size-main-xl) * var(--scale-2xl));
    --font-size-48: calc(var(--font-size-48-xl) * var(--scale-2xl));
    --font-size-button: calc(var(--font-size-button-xl) * var(--scale-2xl));
    --font-size-button-secondary: calc(
      var(--font-size-button-secondary-xl) * var(--scale-2xl)
    );
  }
}
@media (min-width: 1728px) {
  :root {
    --font-size-deskMainHeader: calc(var(--font-size-deskMainHeader-xl) * 1.35);
  }
}
@media (min-width: 1910px) {
  :root {
    --font-size-deskMainHeader: calc(
      var(--font-size-deskMainHeader-xl) * var(--scale-3xl)
    );
    --font-size-topBannerText: calc(
      var(--font-size-topBannerText-xl) * var(--scale-3xl)
    );
    --font-size-para: calc(var(--font-size-para-xl) * var(--scale-3xl));
    --font-size-main: calc(var(--font-size-main-xl) * var(--scale-3xl));
    --font-size-48: calc(var(--font-size-48-xl) * var(--scale-3xl));
    --font-size-button: calc(var(--font-size-button-xl) * var(--scale-3xl));
    --font-size-button-secondary: calc(
      var(--font-size-button-secondary-xl) * var(--scale-3xl)
    );
  }
}
@media (min-width: 2240px) {
  :root {
    --font-size-deskMainHeader: calc(var(--font-size-deskMainHeader-xl) * 1.65);
  }
}
@media (min-width: 2560px) {
  :root {
    --font-size-deskMainHeader: calc(
      var(--font-size-deskMainHeader-xl) * var(--scale-4xl)
    );
    --font-size-topBannerText: calc(
      var(--font-size-topBannerText-xl) * var(--scale-4xl)
    );
    --font-size-para: calc(var(--font-size-para-xl) * var(--scale-4xl));
    --font-size-main: calc(var(--font-size-main-xl) * var(--scale-4xl));
    --font-size-48: calc(var(--font-size-48-xl) * var(--scale-4xl));
    --font-size-button: calc(var(--font-size-button-xl) * var(--scale-4xl));
    --font-size-button-secondary: calc(
      var(--font-size-button-secondary-xl) * var(--scale-4xl)
    );
  }
}

/* Custom utility classes */

.page-main-header-size {
  font-size: var(--font-size-deskMainHeader);
}
.top-banner-size {
  font-size: var(--font-size-topBannerText);
}
.common-title-size {
  font-size: var(--font-size-main);
}
.common-para-size {
  font-size: var(--font-size-para);
}
.secondary-header-size {
  font-size: var(--font-size-48);
}
.common-button-size {
  font-size: var(--font-size-button);
}
.common-button-secondary-size {
  font-size: var(--font-size-button-secondary);
}

/* common clip paths */
.bottom-right-slant {
  clip-path: polygon(0 0, 100% 0, 100% 75%, 90% 100%, 0 100%);
}
.top-left-slant {
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 0 100%, 0 14%);
}
@media (min-width: 640px) and (max-width: 767px) {
  /* .about-ul ul::after {
    right: -50px;
  } */
}
/* common components style */

.home-our-services-text {
  color: #ff8200;
}
.auido-explore-text {
  color: #61e063;
}
.accept-btn:hover::before {
  content: "";
  width: 52px;
  height: 20px;
  border: 2px solid #ff8200;
  transform: rotate(239deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  top: 61px;
  right: -2rem;
  backdrop-filter: blur(18px);
}
@media (min-width: 2560px) {
  .accept-btn:hover::before {
    top: 67px;
  }
}
/* .accept-btn:hover::before {
  content: "";
  width: 21px;
  height: 20px;
  background: #1a1a1a;
  border: 2px solid #ff8200;
  transform: rotate(238deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  top: 42px;
  right: -12px;
} */
.accept-all:hover {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 93% 100%, 0 100%);
}
.bottom-right-slant {
  clip-path: polygon(
    calc(100% - 1.4rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 0.9rem)
  );
}
.bottom-right-slant-lg {
  clip-path: polygon(
    calc(100% - 1.6rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 1rem)
  );
}
.bottom-right-slant {
  clip-path: polygon(
    calc(100% - 1.4rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 0.9rem)
  );
}
.bottom-right-slant-hover:hover {
  clip-path: polygon(
    calc(100% - 1.4rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 0.9rem)
  );
}
.bottom-right-slant-sm {
  clip-path: polygon(
    calc(100% - 1.2rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 0.9rem)
  );
}
.bottom-right-slant-hover-sm:hover {
  clip-path: polygon(
    calc(100% - 1.2rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 0.9rem)
  );
}
.bottom-right-slant-lg {
  clip-path: polygon(
    calc(100% - 1.6rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 1rem)
  );
}
.bottom-right-slant-hover-lg:hover {
  clip-path: polygon(
    calc(100% - 1.6rem) 100%,
    0 100%,
    0 0,
    100% 0,
    100% calc(100% - 1rem)
  );
}
.arrow-btn-top-left {
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
}
.slide-btn-top-left-slant:hover {
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
}
.arrow-btn-bottom-right {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 70% 100%, 0 100%);
}
.slide-btn-bottom-right-slant:hover {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 70% 100%, 0 100%);
}
