/* careers Page css goes here, all the classnames shoud start from .careers-yourclassname */
.careers-d {
  background-image: url("/public/images/careers-d.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 1rem 0;
  background-position: right;
}
.hover-reset-svg-careers:hover svg path {
  stroke: #ff8200;
}
.hover-reset-svg-careers-purple:hover svg path {
  stroke: #ff5dd2;
}

/* Custom scrollbar styles for .custom-scroll-careers-filter class */
.custom-scroll-careers::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll-careers::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scroll-careers::-webkit-scrollbar-thumb {
  background: #ff5dd2;
  border-radius: 10px;
}
.custom-scroll-careers::-webkit-scrollbar-thumb:hover {
  background: #ff5dd2;
}
/* Custom scrollbar styles for .custom-scroll-careers-filter-desk class */
.custom-scroll-careers-desk::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll-careers-desk::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scroll-careers-desk::-webkit-scrollbar-thumb {
  background: #ff8200;
  border-radius: 10px;
}
.custom-scroll-careers-desk::-webkit-scrollbar-thumb:hover {
  background: #ff8200;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

/* For Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
