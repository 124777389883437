.quote::before {
  content: "";
  background: url("/public/images/stroke.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  height: 10rem;
  inset: 0;
}

@media only screen and (max-width: 1024px) {
  .quote::before {
    top: 1rem;
  }
}
@media only screen and (max-width: 600px) {
  .quote::before {
    top: 4rem;
  }
}
.quote p {
  padding-left: 7rem;
}
.bg-img-quotes {
  background-image: url("/public/images/project.png");
  background-repeat: no-repeat;
  background-size: cover;
}
