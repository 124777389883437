@font-face {
    font-family: Proxima-Regular;
    src: url(./font/ProximaNova-Regular.otf);
}
html{
    padding: 0;
    margin: 0;
}
/* body{
    font-family: Proxima-Regular;
    padding: 0;
    margin: 0;
} */
 .font {
    font-family: Proxima-Regular;
    padding: 0;
    margin: 0;
 }
/* p{
    color: #1F1F24;
    font-size: 1.2rem;
    margin: 0;
    line-height: 1.5;
    word-break: break-word;
} */
/* li{
    color: #1F1F24;
    font-size: 1.2rem;
    margin: 0;
    line-height: 1.5;
    word-break: break-word;
} */
/* a{
    color: #1F1F24;
    font-weight: bold;
    word-break: break-word;
} */

/* :root{
    font-size: 16px;
} */

@media screen and (max-width:1400px) {
    :root{
        font-size: 14px;
    }
}
.text-center{
    text-align: center;
}
.it-navbar{
    background-color: #2B2C3A;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: sticky;
    top: 0;
}
.it-navbar img{
    width: 75px;
    height: auto;       
}
.page_height{
    min-height: 100vh;
    background-image: url(https://d1r5h1ay0no5pj.cloudfront.net/side/wp-content/uploads/2025/02/collaborate_bg_v1.png);
    /* background-repeat: no-repeat; */
    background-size: 100% auto;
    background-position: center;
}
.it-navbar .clippath-logo {
    clip-path: polygon( 20px 0%, calc(100% - 20px) 0%, calc(100% - 30px) 100%, 20px 100%, 0 calc(100% - 20px));
    background: #2B2C3A;
    width: 120px;
    display: block;
    height: 8px;
    position: absolute;
    bottom: -4px;
    margin-left: 10px;
}
.itsupport_page.circle-bg{
    /* background-image: url(https://d323sccto6ke4l.cloudfront.net/images/localizationqa/local/en-us/collaborate_bg_v1.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top; 
    display: flex;
    align-items: center;
    /* height: calc(100vh - 60px); */
    /* height: 90%; */
}
@media screen and (min-width:1750px) {
    /* .itsupport_page.circle-bg{
        margin-top: 5rem;
    } */
}
@media screen and (max-width:768px) {
    /* .itsupport_page.circle-bg{
        margin-top: 0rem;
    } */
}
.itsupport_page_card{
    width: 70%;
    margin: 0rem auto;
    padding: 4rem 2rem;
    /*border-radius: 1rem;*/
    box-shadow: 0 0px 10px 3px #cfcfcf;
    /* height: 90%; */
    min-height: 91vh;
}
@media screen and (max-width:1400px) {
    .itsupport_page_card{
        width: 70%;
    }
}
@media screen and (max-width:768px) {
    .itsupport_page_card {
        width: 82%;
      /*  margin: 1rem; */
    }
}
@media screen and (max-width:576px) {
    .itsupport_page_card {
        width: 78%;
      /*  margin: 1rem; */
    }
}
.itsupport_page h1{
    color: #EE6E03;
    font-size: 2.5rem;
    margin: 0;
    text-align: center;
    margin-bottom: 2rem;
}
.itsupport_page h2{
    color: #EE6E03;
    margin: 2rem 0 0;
	font-weight:bold;
    font-size: 20px;
}
.itsupport_page button{
    color: #fff;
    border: 1px solid #EE6E03 !important;
    font-family: Proxima-regular;
    letter-spacing: 1px;
    padding: 15px 25px;
    border-radius: unset;
    transition: .2s ease;
    background-color: #EE6E03;
    margin: 2rem 0 0;
    cursor: pointer;
    font-weight: 600;
	font-size:18px;
}
.itsupport_page button:hover {
    background-color: #fff;
    color: #F47F2C;
    /*padding: 15px 25px; */
}

.itsupport_page_card p
{
font-size:18px;
}