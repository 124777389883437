:root {
  /* Base font sizes for different sections */
  --font-size-ntitle-xl: 18px;
  --font-size-ndate-xl: 18px;
  --font-size-nbutton-xl: 16px;
  --font-size-nheader-xl: 36px;
  /* Card dimensions */
  --card-nwidth-xl: 21rem;
  --card-nheight-xl: 28rem;
  /* Scaling factors */
  --scale-xxs: 0.8;
  --scale-xs: 1;
  --scale-sm: 1;
  --scale-md: 1;
  --scale-lg: 0.84;
  --scale-2xl: 1.2;
  --scale-3xl: 1.5;
  --scale-4xl: 1.8;
}

/* Media queries for scaling */
/* @media (max-width: 424px) { */
:root {
  --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-xxs));
  --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-xxs));
  --font-size-nbutton: calc(var(--font-size-nbutton-xl) * var(--scale-xxs));
  --font-size-nheader: calc(var(--font-size-nheader-xl) * var(--scale-xxs));
  --card-nwidth: calc(var(--card-nwidth-xl) * var(--scale-xxs));
  --card-nheight: calc(var(--card-nheight-xl) * var(--scale-xxs));
}
/* } */
@media (min-width: 360px) {
  :root {
    --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-xs));
    --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-xs));
    --font-size-nbutton: calc(var(--font-size-nbutton-xl) * var(--scale-xs));
    --font-size-nheader: calc(var(--font-size-nheader-xl) * var(--scale-xs));
    --card-nwidth: calc(var(--card-nwidth-xl) * 0.9);
    --card-nheight: calc(var(--card-nheight-xl) * var(--scale-xs));
  }
}

@media (min-width: 640px) {
  :root {
    --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-sm));
    --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-sm));
    --font-size-nbutton: calc(var(--font-size-nbutton-xl) * 0.9);
    --font-size-nheader: calc(var(--font-size-nheader-xl) * 0.8);

    --card-nwidth: calc(var(--card-nwidth-xl) * 0.7);
    --card-nheight: calc(var(--card-nheight-xl) * 0.8);
  }
}

@media (min-width: 768px) {
  :root {
    --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-md));
    --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-md));
    --font-size-nbutton: calc(var(--font-size-nbutton-xl) * var(--scale-md));
    --font-size-nheader: calc(var(--font-size-nheader-xl) * var(--scale-md));

    --card-nwidth: calc(var(--card-nwidth-xl) * 0.9);
    --card-nheight: calc(var(--card-nheight-xl) * 1.05);
  }
}

@media (min-width: 1024px) {
  :root {
    --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-lg));
    --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-lg));
    --font-size-nbutton: calc(var(--font-size-nbutton-xl) * var(--scale-lg));
    --font-size-nheader: calc(var(--font-size-nheader-xl) * var(--scale-lg));

    /* --card-nwidth: calc(var(--card-nwidth-xl) * var(--scale-lg)); */
    --card-nwidth: 33%;

    --card-nheight: calc(var(--card-nheight-xl) * var(--scale-lg));
  }
}

@media (min-width: 1280px) {
  :root {
    --font-size-ntitle: var(--font-size-ntitle-xl);
    --font-size-ndate: var(--font-size-ndate-xl);
    --font-size-nbutton: var(--font-size-nbutton-xl);
    --font-size-nheader: var(--font-size-nheader-xl);

    /* --card-nwidth: var(--card-nwidth-xl);
     */
    --card-nwidth: 33%;

    --card-nheight: var(--card-nheight-xl);
  }
}

@media (min-width: 1408px) {
  :root {
    --card-nheight: calc(var(--card-nheight-xl) * 1.13);
  }
}

@media (min-width: 1528px) {
  :root {
    --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-2xl));
    --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-2xl));
    --font-size-nbutton: calc(var(--font-size-nbutton-xl) * var(--scale-2xl));
    --font-size-nheader: calc(var(--font-size-nheader-xl) * var(--scale-2xl));

    /* --card-nwidth: calc(var(--card-nwidth-xl) * var(--scale-2xl)); */
    --card-nwidth: 33%;

    --card-nheight: calc(var(--card-nheight-xl) * var(--scale-2xl));
  }
}
@media (min-width: 1728px) {
  :root {
    --card-nheight: calc(var(--card-nheight-xl) * 1.35);
  }
}
@media (min-width: 1910px) {
  :root {
    --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-3xl));
    --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-3xl));
    --font-size-nbutton: calc(var(--font-size-nbutton-xl) * var(--scale-3xl));
    --font-size-nheader: calc(var(--font-size-nheader-xl) * var(--scale-3xl));

    /* --card-nwidth: calc(var(--card-nwidth-xl) * var(--scale-3xl)); */
    --card-nwidth: 33%;

    --card-nheight: calc(var(--card-nheight-xl) * var(--scale-3xl));
  }
}
@media (min-width: 2240px) {
  :root {
    --card-nheight: calc(var(--card-nheight-xl) * 1.65);
  }
}
@media (min-width: 2560px) {
  :root {
    --font-size-ntitle: calc(var(--font-size-ntitle-xl) * var(--scale-4xl));
    --font-size-ndate: calc(var(--font-size-ndate-xl) * var(--scale-4xl));
    --font-size-nbutton: calc(var(--font-size-nbutton-xl) * var(--scale-4xl));
    --font-size-nheader: calc(var(--font-size-nheader-xl) * var(--scale-4xl));

    /* --card-nwidth: calc(var(--card-nwidth-xl) * var(--scale-4xl) * 1.11); */
    --card-nwidth: 33%;

    --card-nheight: calc(var(--card-nheight-xl) * var(--scale-4xl) * 1.05);
  }
}

/* Custom utility classes */
.text-events-ntitle {
  font-size: var(--font-size-ntitle);
}

.text-events-ndate {
  font-size: var(--font-size-ndate);
}

.text-events-nbutton {
  font-size: var(--font-size-nbutton);
}
.text-events-nheader {
  font-size: var(--font-size-nheader);
}
.events-card {
  width: var(--card-nwidth);
  height: var(--card-nheight);
}
.events-card-child {
  height: var(--card-nheight);
}
.home-color-news-text {
  color: #2ad9ff;
}
.home-color-news-border {
  border-color: #2ad9ff;
}
.home-color-news-bg:hover {
  background-color: #2ad9ff;
}
.audio-service-lab-text {
  color: #fff647;
}
.audio-service-lab-border {
  border-color: #fff647;
}
.audio-service-lab-btn-hover:hover {
  background-color: #fff647;
}
