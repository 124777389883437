/* projectslist Page css goes here, all the classnames shoud start from .projectslist-yourclassname */
.bg-project {
  /* background-image: url("/public/images/project-d.png"); */
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  padding: 1rem 0;
}
/* Custom scrollbar styles for .custom-scroll-filter-filter class */
.custom-scroll-filter::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll-filter::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scroll-filter::-webkit-scrollbar-thumb {
  background: #68ffd2;
  border-radius: 10px;
}
.custom-scroll-filter::-webkit-scrollbar-thumb:hover {
  background: #68ffd2;
}
.transform-center {
  transform: translate(-50%, -50%);
}
/* .reset-hover svg path:hover {
  fill: #fce57e !important;
}
.get-btn:hover::before {
  content: "";
  width: 21px;
  height: 20px;
  background: #1a1a1a;
  border: 3px solid #2ad9ff;
  transform: rotate(215deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  top: 40px;
  right: -13px;
} */
.reset-slant:hover::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #1a1a1a;
  border: 1px solid #68ffd2;
  transform: rotate(212deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  top: 40px;
  right: -13px;
}
@media (min-width: 1024px) {
  .reset-slant:hover::before {
    top: 28px;
  }
}
@media (min-width: 1280px) {
  .reset-slant:hover::before {
    top: 30px;
  }
}
@media (min-width: 1396px) {
  .reset-slant:hover::before {
    top: 32px;
  }
}
@media (min-width: 1528px) {
  .reset-slant:hover::before {
    top: 40px;
  }
}
@media (min-width: 1910px) {
  .reset-slant:hover::before {
    top: 45px;
  }
}
@media (min-width: 2048px) {
  .reset-slant:hover::before {
    top: 45px;
  }
}
@media (min-width: 2304px) {
  .reset-slant:hover::before {
    top: 44px;
  }
}
@media (min-width: 2560px) {
  .reset-slant:hover::before {
    top: 52px;
  }
}
@media screen and (max-width: 600px) {
  .project-slant-bottom-mobile {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 85% 100%, 0 100%);
  }
}
@media screen and (max-width: 640px) {
  .reset-slant:hover::before {
    top: 55px;
  }
}

@media screen and (max-width: 768px) {
  .reset-slant:hover::before {
    top: 32px;
  }
}
.hover-reset-svg:hover svg path {
  stroke: #ffffff;
}
.hover-svg:hover svg path {
  stroke: #1a1a1a;
}
.hover-badge p {
  background-color: blue;
}
