@media (min-width: 640px) {
  .service-slide-active {
    transform: translateY(-2.5rem) !important;
  }
}
/* .sprint-effect {
  animation: spring 0.3s ease-out;
}
@keyframes spring {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
} */
.service-slide {
  background-color: #46464680;
  color: #fff;
  transition: all 1s ease;
}
.slider-cards-top-left-slant {
  clip-path: polygon(2rem 0, 100% 0, 100% 100%, 0 100%, 0 2.5rem);
}
@media (min-width: 1024px) {
  .slider-cards-top-left-slant {
    clip-path: polygon(2.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem);
  }
}
@media (min-width: 1528px) {
  .slider-cards-top-left-slant {
    clip-path: polygon(3rem 0, 100% 0, 100% 100%, 0 100%, 0 3.5rem);
  }
}
.slide-btn-top-left-slant:hover {
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
}
.slide-btn-bottom-right-slant:hover {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 70% 100%, 0 100%);
}
/* @media (min-width: 1024px) {
  .codev-top-left-slant {
    clip-path: polygon(3.5rem 0, 100% 0, 100% 100%, 0 100%, 0 4rem);
  }
} */
