/* About Page css goes here, all the classnames shoud start from .about-yourclassname */
/* Target the element */
.their-words::before {
  content: "";
  background-image: url("/public/images/quote-up.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: -2.7rem;
  left: 3rem;
  z-index: 2;
}
.their-words::after {
  content: "";
  background-image: url("/public/images/quote-down.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  width: 8rem;
  height: 8rem;
  position: absolute;
  /* top: 1.3rem; */
  right: 2rem;
  bottom: 1.3rem;
  z-index: 2;
}
.bg-awards {
  background-image: url("/public/images/d-blue.png");
  background-repeat: no-repeat;
}
.bg-life {
  background-image: url("/public/images/life-bg.png");
  background-repeat: no-repeat;
}
.hover-btn:hover {
  clip-path: polygon(0 0, 100% 0, 100% 65%, 95% 100%, 0 100%);
}
.transform-leader {
  transform: translate(-50%, 0);
}
.bg-about {
  background-image: url("/public/images/about-banner-desktop.png");
  background-repeat: no-repeat;
}
.active-dice::before {
  content: "";
  border-left: 2px solid #fff647;
  height: 20rem;
  width: 2px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 118%;
}

:root {
  --position-1-x: 0;
  --position-1-y: 0;
  --position-1-rotate: -5deg;
  --position-2-x: 140px;
  --position-2-y: 0;
  --position-2-rotate: 5deg;
  --position-3-x: 290px;
  --position-3-y: 0;
  --position-3-rotate: 10deg;
}
@media (min-width: 320px) {
  .mobile-about-slant {
    clip-path: polygon(0 0, 100% 0, 100% 91%, 90% 100%, 0 100%);
  }
}
@media (min-width: 768px) {
  :root {
    --position-1-x: 0;
    --position-1-y: 0;
    --position-1-rotate: -5deg;
    --position-2-x: 160px;
    --position-2-y: 0;
    --position-2-rotate: 5deg;
    --position-3-x: 320px;
    --position-3-y: 0;
    --position-3-rotate: 10deg;
  }
  .mobile-about-slant {
    clip-path: polygon(0 0, 100% 0, 100% 87%, 85% 100%, 0 100%);
  }
}
@media (min-width: 1024px) {
  :root {
    --position-1-x: -10px;
    --position-1-y: 0;
    --position-1-rotate: -5deg;
    --position-2-x: 120px;
    --position-2-y: 0;
    --position-2-rotate: 5deg;
    --position-3-x: 250px;
    --position-3-y: 0;
    --position-3-rotate: 10deg;
  }
  .mobile-about-slant {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 95% 100%, 0 100%) !important;
  }
}
@media (min-width: 1280px) {
  :root {
    --position-1-x: -40px;
    --position-1-y: 0;
    --position-1-rotate: -5deg;
    --position-2-x: 120px;
    --position-2-y: 0;
    --position-2-rotate: 5deg;
    --position-3-x: 260px;
    --position-3-y: 0;
    --position-3-rotate: 10deg;
  }
}
@media (min-width: 1408px) {
  :root {
    --position-1-x: -60px;
    --position-1-y: 0;
    --position-1-rotate: -5deg;
    --position-2-x: 120px;
    --position-2-y: 0;
    --position-2-rotate: 5deg;
    --position-3-x: 280px;
    --position-3-y: 0;
    --position-3-rotate: 10deg;
  }
}
@media (min-width: 1910px) {
  :root {
    --position-1-x: -80px;
    --position-1-y: 0;
    --position-1-rotate: -5deg;
    --position-2-x: 120px;
    --position-2-y: 0;
    --position-2-rotate: 5deg;
    --position-3-x: 340px;
    --position-3-y: 0;
    --position-3-rotate: 10deg;
  }
}

.leadership-scroll::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
}

.leadership-scroll::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
}

.leadership-scroll::-webkit-scrollbar-thumb {
  background: #004780; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
  border-radius: 4px;
}

.leadership-scroll::-webkit-scrollbar-thumb:hover {
  background: #004780; /* Color of the thumb when hovered */
}
